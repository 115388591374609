<template>
<div id="page-tipos-precios">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Tipos de Precios" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover
                    v-if="!['Público General','Mayoreo','Mayoreo 2'].includes(props.row.nombre)"
                  >
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row)" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Tipo de Precio</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required]" v-on:keydown.enter.prevent='1'></v-text-field>
              </v-col>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Orden" v-model="model.orden" :rules="[rules.required]" v-on:keydown.enter.prevent='1' type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  mounted: function() {
    if (!this.verificaPermiso('t.utilerias.tipos.precio')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.getTiposPrecios();
  },
  data() {
    return {
      id_configuracion: "",
      fab: [],
      name: "datagrid",
      columns: [
        "nombre",
        "estatus",
        "orden",
        "actions"
      ],
      show_loading: true,
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["nombre", "estatus"],
        sortable: ["nombre", "estatus"],

        headings: {
          nombre: "Nombre",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns:{
          estatus: [{
                      id: 'Activo',
                      text: "Activo"
                  },
                  {
                      id: 'Inactivo',
                      text: "Inactivo"
                  }
              ]
          },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: ""
        },
        {
          text: "Tipos de Precios",
          disabled: true,
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        nombre: "",
        estatus: "Activo",
        orden: 0
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },

      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model.nombre = "";
        this.model.uuid = "";
        this.model.orden = 0;

      } else {
        this.update = true;
      }

      this.modal = true;
    },
    getTiposPrecios: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {
          "type": "configuracion"
        },
        "fields": ["_id", "tipos_precio"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.id_configuracion = response.data.docs[0]._id;
            this.registros.items = response.data.docs[0].tipos_precio != undefined ? response.data.docs[0].tipos_precio : [];            
          }

          window.dialogLoader.hide();
        })
        .catch(error => {
          console.log(error)
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    getRegistro: function(item) {
      this.model = {
        uuid: item.uuid,
        nombre: item.nombre,
        estatus: item.estatus,
        orden: item.orden
      };
      this.nombre_anterior = item.nombre;
      this.openModal(2);
    },
    saveRegistro: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/agregar_tipo_precios/" + this.id_configuracion;
        var existe = this.registros.items.filter(x => this.model.orden == x.orden && this.model.uuid != x.uuid);
        if(existe.length > 0) {
          window.dialogLoader.hide();
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ya existe un registro con ese orden",
            footer: ""
          });
          return;
        }

        let data = {
          uuid: this.$uuid.v4(),
          nombre: this.model.nombre.toString().trim(),
          estatus: this.model.estatus,
          orden: this.model.orden
        };

        window.axios
          .post(url, data)
          .then(response => {
            if (response.data == true) {
              this.modal = false;
              this.getTiposPrecios();
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al guardar el registro.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });

      }
    },
   
    updateRegistro: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');

        var existe = this.registros.items.filter(x => this.model.orden == x.orden && this.model.uuid != x.uuid);
        if(existe.length > 0) {
          window.dialogLoader.hide();
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ya existe un registro con ese orden",
            footer: ""
          });
          return;
        }

        let data = this.model;        
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/update_tipo_precio/" + this.id_configuracion;

        window.axios
          .put(url, data)
          .then(response => {
            if (response.data == true) {
              if (this.nombre_anterior != this.model['nombre']){
                  var datos = {
                  'campo': 'precios_venta',
                  'oldvalue': this.nombre_anterior,
                  'newvalue': this.model['nombre'],
                  };
                  window.funciones.updateArticulos(datos)
                  .then(result => {
                      console.log("Actualización correcta");
                  }).catch(error => {
                      console.log("Error al actualizar articulos: ",error);                              
                  });
              } 
              this.modal = false;
              this.getTiposPrecios();
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
    deleteRegistro: function(item) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/delete_tipo_precio/" + this.id_configuracion;

          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .post(url, item)
            .then(response => {
              if (response.data == true) {
                this.modal = false;
                this.getTiposPrecios();
                window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                  color: 'success'
                });
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });

            }).then(() => {
              window.dialogLoader.hide();
            });
        }
      });
    },//

  }
};
</script>
